import React, { useEffect, useState } from 'react'
import NewsPosts from '../../assets/news'
import PageTemplate from '../../templates/components/Page'
import NewsPost from '../../templates/post/NewsPost'

import AtomixPost from '../../assets/news/article-atomix/content'
import AtomixCover from '../../assets/news/article-atomix/cover.png'

export type NewsPostContent = {
  title: string
  date: string
  content: string[]
} | null

const getArticleSlug = (path: string): string => {
  const lastSlashIndex = path.lastIndexOf('/')
  return path.slice(lastSlashIndex + 1)
}

const getArticleContent = (directory?: string): [NewsPostContent, any] => {
  switch (directory) {
    case 'article-atomix':
      return [AtomixPost, AtomixCover]
    default:
      return [null, null]
  }
}

type NewsPostProps = {
  location: Location
}

const NewsPostWrapper = (props: NewsPostProps) => {
  const [postContent, setPostContent] = useState<NewsPostContent>(null)
  const [coverImage, setCoverImage] = useState(null)

  useEffect(() => {
    const pageSlug = getArticleSlug(props.location.pathname)
    const postMetadata = NewsPosts.find((metadata) => metadata.slug === pageSlug)
    const postContent = getArticleContent(postMetadata?.directory)
    setPostContent(postContent[0])
    setCoverImage(postContent[1])
  })

  return (
    <PageTemplate>
      <NewsPost content={postContent} coverImage={coverImage} />
    </PageTemplate>
  )
}

export default NewsPostWrapper
