const articleAtomix = {
  title: 'Announcing the Future Atomix Connoisseurs',
  date: 'August 16, 2022',
  content: [
    'Future EMP Connoisseurs was originally a group chat of four friends who yearned to dine at Eleven Madison Park. That group chat spawned this culinary exploration project a year ago, and ever since, we’ve tried our best to give unfiltered, unadulterated, and unapologetic versions of our experience at various culinary institutions. We never expected that people would actually care about our thoughts or even respect them, so thank you so much for your engagement and encouragement.',
    'But over time, this account has transcended to answering one crucial question: is a restaurant worth visiting? And given our unique perspective as students (on a finite and dwindling budget with how much money we’ve spent on food this past year), we’re always on the lookout for the most valued bite out there. We’ve visited and reviewed over sixty restaurants across twenty cuisines now, ranging from your favorite local diner to the world’s best restaurants. And this search does not end here: we will always be Future Connoisseurs of *some* restaurant.',
    'Having crossed EMP off our checklist, we’re turning our attention to a new institution. Combining ancient Korean traditions with ground-breaking modern technique, Junghyun and Ellia Park have created the most exciting restaurant in the country. Currently ranked the top restaurant in the United States by the World’s 50 Best and winning two Michelin stars, this restaurant takes prestige and artistry to a new level. We don’t know when (or how) we’re gonna dine there, but it’s time to yearn for somewhere new.',
    'Say hello to the Future Atomix Connoisseurs.',
  ],
}

export default articleAtomix
