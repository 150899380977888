import React from 'react'
import { NewsPostContent } from '../../pages/news/[slug]'
import '../review/Standard.scss'
import './NewsPost.scss'

type NewsPostProps = {
  content: NewsPostContent
  coverImage: any
}

const NewsPost = ({ content, coverImage }: NewsPostProps) => {
  return (
    <>
      <div className="postPictures">
        <img className="newsPostCoverImage" src={coverImage} />
      </div>
      <div className="newsPostHeader">
        <div>{content?.title}</div>
      </div>
      <div className="newsPostSubtitle">
        <div>{content?.date}</div>
      </div>
      <div className="newsPostBody">
        {content?.content.map((paragraph, index) => {
          return (
            <div key={index} className="newsPostParagraph">
              {paragraph}
            </div>
          )
        })}
      </div>
    </>
  )
}

export default NewsPost
